@font-face {
  font-family: "Nunito";
  font-weight: 200;
  font-display: fallback;
  src: url("./fonts/Nunito-ExtraLight.ttf") format("truetype");
}

.App {
  background-color: var(--myrtle-green);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
}

.content {
  color: var(--azure-white);
  width: 65%;
  font-size: 20px;
}

:root {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  /* --black-coffee: #483D3F;
  --green-blue-crayola: #058ED9;
  --eggshell: #F4EBD9;
  --spanish-gray: #A39A92;
  --dim-gray: #77685D; */

  --myrtle-green: #17252a;
  --dark-keppel: #2b7a78;
  --keppel: #3aafa9;
  --azure-white: #def2f1;
  --plain-white: #feffff;
  --warm-orange: #c7591e;
}
